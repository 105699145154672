import { Container, Grid, Box, Link, Typography } from "@mui/material"
import Footer from "components/Footer"

const Page = ({ children }) => {
  return (
    <>
      <Grid container wrap="wrap">
        <Box margin={5}>
          <Link 
            href={"/"} 
            sx={{
              "color": "black",
              "textDecoration": "none",
              "cursor": "crosshair"
            }}
            >
            <Typography fontFamily="General Sans"fontSize="16px">
              /
            </Typography>
          </Link>
        </Box>
        <Grid item xs={12} sm={12} md={12}>
          <Box margin={5}></Box>
          <Container>
            {children}
          </Container>
          <Footer/>
        </Grid>
      </Grid>
    </>
  )
}

export default Page